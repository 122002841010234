import clsx from 'clsx';
import type * as React from 'react';

import * as styles from './index.css';

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {}

function Badge({ className, ...props }: BadgeProps) {
  return <div className={clsx(styles.badgeStyle, className)} {...props} />;
}

export { Badge };
