import './styles.css';

import {
  ArrowDownBigIcon,
  ArrowUpBigIcon,
  DoneIcon,
} from '@blocksuite/icons/rc';
import type {
  SelectItemProps,
  SelectProps as _SelectProps,
} from '@radix-ui/react-select';
import {
  Content,
  Group,
  Icon,
  Item,
  ItemIndicator,
  ItemText,
  Label,
  Portal,
  Root,
  ScrollDownButton,
  ScrollUpButton,
  Trigger,
  Value,
  Viewport,
} from '@radix-ui/react-select';
import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import React from 'react';

export type SelectProps = PropsWithChildren<{
  selectRoot?: _SelectProps;
}>;

export const Select = ({ children, selectRoot }: SelectProps) => (
  <Root {...selectRoot}>
    <Trigger className="SelectTrigger">
      <Value placeholder="Select" />
      <Icon className="SelectIcon">
        <ArrowDownBigIcon />
      </Icon>
    </Trigger>
    <Portal className="SelectPortal">
      <Content className="SelectContent">
        <ScrollUpButton className="SelectScrollButton">
          <ArrowUpBigIcon />
        </ScrollUpButton>
        <Viewport className="SelectViewport">{children}</Viewport>
        <ScrollDownButton className="SelectScrollButton">
          <ArrowDownBigIcon />
        </ScrollDownButton>
      </Content>
    </Portal>
  </Root>
);

export const SelectItem = React.forwardRef<HTMLDivElement, SelectItemProps>(
  ({ children, className, ...props }: SelectItemProps, forwardedRef) => {
    return (
      <Item
        className={clsx('SelectItem', className)}
        onFocus={event => event.stopPropagation()}
        {...props}
        ref={forwardedRef}
      >
        <ItemText>{children}</ItemText>
        <ItemIndicator className="SelectItemIndicator">
          <DoneIcon />
        </ItemIndicator>
      </Item>
    );
  }
);

SelectItem.displayName = 'SelectItem';

export { Group, Label };
