import { WorkspacePermissionService } from '@affine/core/modules/permissions';
import { useI18n } from '@affine/i18n';
import { ViewLayersIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';

import { ExplorerEmptySection } from '../../layouts/empty-section';

export const RootEmpty = ({
  onClickCreate,
}: {
  onClickCreate?: () => void;
}) => {
  const t = useI18n();

  // DNB_FIX
  const isReadOnly = useLiveData(
    useService(WorkspacePermissionService).permission.isReadOnly$
  );

  return (
    <ExplorerEmptySection
      icon={ViewLayersIcon}
      message={t['com.affine.collections.empty.message']()}
      messageTestId="slider-bar-collection-empty-message"
      // DNB_FIX проверить права
      actionText={
        !isReadOnly
          ? t['com.affine.collections.empty.new-collection-button']()
          : ''
      }
      onActionClick={onClickCreate}
    />
  );
};
