export { CollectionService } from './services/collection';

import {
  type Framework,
  WorkspaceScope,
  WorkspaceService,
} from '@toeverything/infra';

import { WorkspacePermissionService } from '../permissions';
import { CollectionService } from './services/collection';

// DNB_FIX добавить WorkspacePermissionService
export function configureCollectionModule(framework: Framework) {
  framework
    .scope(WorkspaceScope)
    .service(CollectionService, [WorkspaceService, WorkspacePermissionService]);
}
