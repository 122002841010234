import { Button } from '@affine/component';
import { WorkspacePermissionService } from '@affine/core/modules/permissions';
import { useI18n } from '@affine/i18n';
import { useLiveData, useService } from '@toeverything/infra';
import type { ReactElement } from 'react';

import * as styles from './collection-list-header.css';

export const CollectionListHeader = ({
  node,
  onCreate,
}: {
  node: ReactElement | null;
  onCreate: () => void;
}) => {
  // DNB_FIX получить isReadOnly
  const isReadOnly = useLiveData(
    useService(WorkspacePermissionService).permission.isReadOnly$
  );
  const t = useI18n();

  return (
    <>
      <div className={styles.collectionListHeader}>
        <div className={styles.collectionListHeaderTitle}>
          {t['com.affine.collections.header']()}
        </div>
        {/* DNB_FIX скрывать кнопки, если isReadOnly */}
        {!isReadOnly && (
          <Button
            className={styles.newCollectionButton}
            onClick={onCreate}
            data-testid="all-collection-new-button"
          >
            {t['com.affine.collections.empty.new-collection-button']()}
          </Button>
        )}
      </div>
      {node}
    </>
  );
};
