import { useRuntimeDynamicConfig } from '@affine/env/global';
import {
  GlobalStateService,
  LiveData,
  useLiveData,
  useService,
} from '@toeverything/infra';
import { type PropsWithChildren, type ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useNavigateHelper } from '../hooks/use-navigate-helper';
import { AuthService } from '../modules/cloud';
import type { AuthSessionInfo } from '../modules/cloud/entities/session';

const allowPathnames = ['/magic-link', '/share'];

// DNB_FIX добавить auth guard для авторизации
export const AuthGuard = ({ children }: PropsWithChildren): ReactNode => {
  const location = useLocation();
  const { jumpToSignIn } = useNavigateHelper();

  const globalState = useService(GlobalStateService).globalState;
  const session = useLiveData(
    LiveData.from(globalState.watch<AuthSessionInfo>('affine-cloud-auth'), null)
  );

  const isRevalidating = useLiveData(
    useService(AuthService).session.isRevalidating$
  );

  const { DNB_REQUIRED_AUTH: requiredAuth } = useRuntimeDynamicConfig();

  useEffect(() => {
    if (
      requiredAuth &&
      !session &&
      !isRevalidating &&
      !allowPathnames.some(pathname => location.pathname.includes(pathname))
    ) {
      jumpToSignIn();
    }
  }, [session, isRevalidating, jumpToSignIn, requiredAuth]);

  return children;
};
