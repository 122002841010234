import { i18nTime } from '@affine/i18n';
import { EdgelessIcon, PageIcon, TodayIcon } from '@blocksuite/icons/rc';
import type { DocMeta } from '@blocksuite/store';
import { DocRecord, Service } from '@toeverything/infra';

import type { WorkspacePropertiesAdapter } from '../../properties';

export class DocDisplayMetaService extends Service {
  constructor(private readonly propertiesAdapter: WorkspacePropertiesAdapter) {
    super();
  }

  // DNB_FIX добавить функция getDisplayMeta
  getDocDisplayMeta(docRecord: DocRecord | DocMeta, originalTitle?: string) {
    if (!(docRecord instanceof DocRecord)) {
      return this.getDisplayMeta(docRecord);
    }

    const journalDateString = this.propertiesAdapter.getJournalPageDateString(
      docRecord.id
    );
    const icon = journalDateString
      ? TodayIcon
      : docRecord.mode$.value === 'edgeless'
        ? EdgelessIcon
        : PageIcon;

    const title = journalDateString
      ? i18nTime(journalDateString, { absolute: { accuracy: 'day' } })
      : originalTitle ||
        docRecord.meta$.value.title ||
        ({
          key: 'Untitled',
        } as const);

    return {
      title: title,
      icon: icon,
      updatedDate: docRecord.meta$.value.updatedDate,
    };
  }
  // DNB_FIX добавить функция getDisplayMeta
  getDisplayMeta(docMeta: DocMeta) {
    const journalDateString = this.propertiesAdapter.getJournalPageDateString(
      docMeta.id
    );
    const icon = journalDateString
      ? TodayIcon
      : docMeta.mode === 'edgeless'
        ? EdgelessIcon
        : PageIcon;

    const title = journalDateString
      ? i18nTime(journalDateString, { absolute: { accuracy: 'day' } })
      : docMeta.title ||
        ({
          key: 'Untitled',
        } as const);

    return {
      title: title,
      icon: icon,
      updatedDate: docMeta.updatedDate,
    };
  }
}
