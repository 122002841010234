import { RadioGroup } from '@affine/component';
import { useLiveData, useService } from '@toeverything/infra';
import { useCallback, useEffect } from 'react';

import { ViewService } from '../../services/view';
import { useActiveSidebar } from '../use-is-active-sidebar';
import { ViewSidebarTabIconTarget } from '../view-islands';
import * as styles from './sidebar-header-switcher.css';

// provide a switcher for active extensions
// will be used in global top header (MacOS) or sidebar (Windows)
export const SidebarHeaderSwitcher = () => {
  const view = useService(ViewService).view;
  const tabs = useLiveData(view.sidebarTabs$);
  /*DNB_FIX activeTabName вместо activeTab*/
  /*const activeTab = useLiveData(view.activeSidebarTab$);*/
  /*  DNB_FIX */
  const { RIGHT_SIDEBAR_TABS_ACTIVE_KEY, activeTabName, globalStateChange } =
    useActiveSidebar();

  const tabItems = tabs.map(tab => ({
    value: tab.id,
    label: (
      <ViewSidebarTabIconTarget
        className={styles.iconContainer}
        viewId={view.id}
        tabId={tab.id}
      />
    ),
    style: { padding: 0, fontSize: 20, width: 24 },
  }));

  const handleActiveTabChange = useCallback(
    (tabId: string) => {
      view.activeSidebarTab(tabId);
      /*  DNB_FIX */
      globalStateChange(RIGHT_SIDEBAR_TABS_ACTIVE_KEY, tabId);
    },
    [RIGHT_SIDEBAR_TABS_ACTIVE_KEY, globalStateChange, view]
  );
  /*  DNB_FIX */
  useEffect(() => {
    if (!activeTabName) return;
    view.activeSidebarTab(activeTabName);
  }, [activeTabName, view]);

  return tabItems.length ? (
    <RadioGroup
      iconMode
      borderRadius={8}
      itemHeight={24}
      padding={4}
      gap={8}
      items={tabItems}
      value={/* DNB_FIX */ /* activeTab?.id */ activeTabName ?? 'journal'}
      onChange={handleActiveTabChange}
    />
  ) : null;
};
