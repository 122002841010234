import "../component/src/components/auth-components/share.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/components/auth-components/share.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61XXY+bOhB9z68Y9arS7oMRkGR365Wu+k+uDB4SN8ZGtgnZVvvfK4xJgIUk296nBDNjnzMfZ8z3A74VhpVo4b/k+XT40Zg1/FoBxF/9D4AzTNlCm5KC0Y45fIg57h5fVwDvK4DtFcPkZWSaxFds108D2/dV1KOJIZJ6p71foZUjVvxECkdmHghhRSEUEr++J2vv7R8aFLu9o/AUx+1arqU2E6dMsvzgPUpmdkIRE1yqU7t4RONEziRhUuwUhVJwLvF1DO1fqGghjHVEF8S9Vfh3MAOQTDunSwqbDolsfffBMn3pFrmwlWRvFAqJfsHDJMJhaSnkqByaGbCSfQ7rZhHrDKrBaYnfvGKcC7U7E1rHHfhKW+GEVhQMSubEcRzXJGqE2+vakb1QbnanNP5woGQZSm98jk0mdX5Yyr/DkyMWc604M2/EmzwuZ+FqoGx5Z5zSCez0dhLC3peYscxqWTtsV6elgoWjcM+5ERqjjT99Ljj+7TkmI8eGGSXUbtE1vJ9z3ninRnC3p5BsO8w9xv55rjhaooajIYZxUVvaqk67qo9oCqkbCnvBOSpvyfLDzuhacTLb9t1Gc+goZYXDPijKoXIUvnx5HWBOJ5jTKeZhcgbZCNri/ztdhX999gbNT4HVTs/z+KcoivbNTyIUxxOFdCkyY1YZFtrgFVppPKbVP98KpazxSm129EOmeqrh8Uwh8RHppwHRRvggtL7Bx4ubEiXrTrhMqSTaWhCqEEo49PXOxpK3vd1cGbMdhVG7LKt20AafwvVUg7bAFrtCCnWYK7qnWXnb3CeUz2O560fBj9o6UbSSFpJtK5YjydA12PXIrWHxcqcw3CddXmnDGO0Pm0Q8ibaf7qNzSXWxGvfPgMw3T6Y/aZ0uDNAPUbtAnQ3XjWL4BpFBi751OF4m2dyMuCeI40vKdN6yEcckjo/7oWzF8bH5n0jf004jYFFjWFUFWf30tYVBFLB5/5KdSCD1HE9DziBywsk7Ljbe7N5r2IfLDYPI1tnlqNv2n9eF7FZCv87ms/0lXBjMuzbKtaxL9SeZHmDJh2N7/aHS+TizSiuvUt9L5IKBzQ2iAqY4PAyyl8Tppjo9etdJFV9jcZZKH4PqdB5VMwT9LtYx4/oPkKWqvHbiyI8H8wnZ7pPlOuFti3TCNw/bTbL6vnr/DaDbZFWXDQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var authCodeContainer = '_17xkjwr6';
export var authCodeErrorMessage = '_17xkjwr8';
export var authCodeWrapper = '_17xkjwr7';
export var authContent = '_17xkjwr5';
export var authInputWrapper = '_17xkjwr1';
export var authPageContainer = '_17xkjwra';
export var formHint = '_17xkjwr2';
export var hideInSmallScreen = '_17xkjwrd';
export var input = '_17xkjwrc';
export var loading = '_17xkjwr4';
export var modalHeaderWrapper = '_17xkjwr0';
export var resendButtonWrapper = '_17xkjwr9';
export var signInPageContainer = '_17xkjwrb';