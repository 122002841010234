import formbricks from '@formbricks/js';
import { ApplicationStarted, OnEvent, Service } from '@toeverything/infra';

import { type AuthService } from '../../cloud';
import { AccountLoggedIn } from '../../cloud/services/auth';

@OnEvent(ApplicationStarted, e => e.onApplicationStart)
@OnEvent(AccountLoggedIn, e => e.onAccountLoggedIn)
export class FormbricksService extends Service {
  private readonly STORAGE_KEY = 'formbricks-js';

  constructor(private readonly auth: AuthService) {
    super();
  }

  getConfig() {
    const environmentId = process.env.FORMBRICKS_ENVIRONMENT_ID || '';
    const apiHost = process.env.FORMBRICKS_API_HOST || '';
    return { environmentId, apiHost };
  }

  async init(
    config?: Partial<Parameters<typeof formbricks.init>[0]> & { email?: string }
  ): Promise<void> {
    if (
      typeof window !== 'undefined' &&
      config?.apiHost &&
      config.environmentId
    ) {
      const { environmentId, apiHost, ...argsConfig } = config;

      const localStorageFormbricks = JSON.parse(
        localStorage.getItem(this.STORAGE_KEY) || '{}'
      );
      if (localStorageFormbricks.status === 'error') {
        localStorage.removeItem(this.STORAGE_KEY);
      }

      await formbricks
        .init({
          environmentId,
          apiHost,
          ...argsConfig,
          errorHandler(args_0, ...args_1) {
            console.error(args_0, ...args_1);
          },
        })
        .catch(err => console.error(err));
    }
  }

  async authorize() {
    const account = this.auth.session.account$.value;
    const config = this.getConfig();

    if (account?.id) {
      await this.init({
        userId: account.id,
        email: account.email,
        ...config,
      }).catch(err => console.error(err));
    }
  }

  async onApplicationStart() {
    await this.authorize();
  }

  async onAccountLoggedIn() {
    await this.authorize();
  }
}

export class FormbricksWorkspaceContextService extends Service {
  constructor() {
    super();
  }
}
