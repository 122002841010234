import { getBaseUrl } from '@affine/graphql';

export function popupWindow(target: string) {
  // DNB_FIX получать базовый адрес через функцию getBaseUrl()
  const baseUrl = getBaseUrl();
  const url = new URL(baseUrl + '/redirect-proxy');
  target = /^https?:\/\//.test(target) ? target : baseUrl + target;
  url.searchParams.set('redirect_uri', target);
  return window.open(url, '_blank', `noreferrer noopener`);
}
