// import { Button } from '@affine/component/ui/button';
// import { useAFFiNEI18N } from '@affine/i18n/hooks';
// import { Logo1Icon } from '@blocksuite/icons';
// import clsx from 'clsx';
// import { useCallback } from 'react';

// import { DesktopNavbar } from './desktop-navbar';
import { CookieBanner } from '../cookie-banner';
import * as styles from './index.css';
// import { MobileNavbar } from './mobile-navbar';

export const AffineOtherPageLayout = ({
  // isSmallScreen,
  children,
}: {
  children: React.ReactNode;
}) => {
  // const t = useAFFiNEI18N();

  // const openDownloadLink = useCallback(() => {
  //   open(runtimeConfig.downloadUrl, '_blank');
  // }, []);

  return (
    <div className={styles.root}>
      {/* DNB_FIX скрыть информацию об AFFiNE */}
      {/* <div
        className={clsx(styles.topNav, {
          mobile: isSmallScreen,
        })}
      >
        <a href="/" rel="noreferrer" className={styles.affineLogo}>
          <Logo1Icon width={24} height={24} />
        </a>
        {isSmallScreen ? (
          <MobileNavbar />
        ) : (
          <>
            <DesktopNavbar />
            <Button onClick={openDownloadLink}>
              {t['com.affine.auth.open.affine.download-app']()}
            </Button>
          </>
        )}
      </div> */}

      {children}
      <CookieBanner />
    </div>
  );
};
