import type { DialogContentProps } from '@radix-ui/react-dialog';
import type { FC, PropsWithChildren } from 'react';

import type { IconButtonProps } from '../../ui/button';
import { Modal } from '../../ui/modal';

export type AuthModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  // DNB_FIX добавить onOutside и onClose
  onOutside?: DialogContentProps['onPointerDownOutside'];
  onClose?: IconButtonProps['onClick'];
};

export const AuthModal: FC<PropsWithChildren<AuthModalProps>> = ({
  children,
  open,
  setOpen,
  // DNB_FIX добавить onOutside и onClose
  onOutside,
  onClose,
}) => {
  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      /*DNB_FIX увеличена ширина*/
      width={440}
      closeButtonOptions={{
        onClick: onClose,
      }}
      contentOptions={{
        ['data-testid' as string]: 'auth-modal',
        // DNB_FIX добавить overflow, т.е. контект может не умещаться
        style: {
          padding: '44px 40px',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
        // DNB_FIX добавить onOutside
        onPointerDownOutside: event => onOutside?.(event),
      }}
    >
      {children}
    </Modal>
  );
};
