export { WorkspacesSearchService } from './services/docs-search';

import {
  type Framework,
  WorkspaceScope,
  WorkspaceService,
  WorkspacesService,
} from '@toeverything/infra';

import { WorkspacesSearchService } from './services/docs-search';

export function configureWorkspacesSearchModule(framework: Framework) {
  framework
    .scope(WorkspaceScope)
    .service(WorkspacesSearchService, [WorkspacesService, WorkspaceService]);
}
