import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

import { RuntimeDynamicConfig } from './dynamic-env';

export const runtimeDynamicConfigContext =
  createContext<RuntimeDynamicConfig | null>(null);

const runtimeDynamicConfig = new RuntimeDynamicConfig();

export const RuntimeDynamicConfigProvider = ({
  children,
}: PropsWithChildren) => {
  const [config, setConfig] = useState<RuntimeDynamicConfig | null>(null);

  useEffect(() => {
    runtimeDynamicConfig
      .load()
      .then(() => {
        setConfig(runtimeDynamicConfig);
      })
      .catch(console.error);
  }, []);

  return (
    <runtimeDynamicConfigContext.Provider value={config}>
      {children}
    </runtimeDynamicConfigContext.Provider>
  );
};

export const useRuntimeDynamicConfig = () => {
  const context = useContext(runtimeDynamicConfigContext);
  return context?.config || {};
};
