import { z } from 'zod';

export const runtimeDynamicFlagsSchema = z.object({
  DNB_YANDEX_METRIC: z.string().or(z.number().optional()).optional(),
  DNB_REQUIRED_AUTH: z.boolean().optional(),
});

export type RuntimeDynamicConfigType = z.infer<
  typeof runtimeDynamicFlagsSchema
>;

export const defaultConfig: RuntimeDynamicConfigType = {
  DNB_YANDEX_METRIC: '',
  DNB_REQUIRED_AUTH: false,
};

export class RuntimeDynamicConfig {
  private _config: RuntimeDynamicConfigType = defaultConfig;

  get config() {
    return this._config;
  }

  set = (config: RuntimeDynamicConfigType) => {
    runtimeDynamicFlagsSchema.parse(config);
    this._config = { ...this.config, ...config };
  };

  load = async (path: string = '/env.json') => {
    const env: RuntimeDynamicConfigType = await fetch(path)
      .then(res => res.json())
      .catch(err => console.error(err));

    this.set(env);
  };
}
