import "../core/src/components/pure/workspace-slider-bar/workspace-card/styles.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/pure/workspace-slider-bar/workspace-card/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW227bMAx9z1cIAQY0QBXIuXSZin7IMBQDI9O2WlkSJDmXDvv3wbKT2o6TrcD6ZJsiKZ4j6tDzn8lLsbHeM/JrQkiBMi8CJ2tmD48TQlLprYIjJ5nCaAAlc01lwNJzIlAHdLU5B8vJpomxkKZS55ww8tBYtsal6KiDVFaek1VjNVVQUiMn2misDXuZhoKThLEv9WcJB9qa1qytRxhlHCc7cHeUQpZJjTTgIVDrZAnuSKPD7HHyezI/I+OF2aGL+ETlfJ3AGnkqfQviNXem0ukgb4waS5j0qFotWjg7dJkye04KmaaoeyGLGHLBZv2kqXQogjSa1/CqUl/wHN18ABfqpeBA+8y4kjevCgJ+v2Oz85pskp39CJuvPRHVVgq6xTeJ7m6+Wt/PF8k9u09mhPk+vB8pBKAggtzh0zS4CqfPZABlvAhak9EnaxndB0cbAeWuJisZ5+6S3w+Q91L5ILO6GXRAHbqN2iXIWBAyHP8vPctmORwtPk21cSWo6XPkoN2OE3YrXwbKX0+IO9TB3064iouZ0YF6+YaDrm7sZewWFdu85XnR8hwd9mcdYLWt8uioR4UivF/XsUOLd/F9AZWS1ksfb3chA1JvQcQrv3dge2Wvx69IVJa2tCvicxPqwY9AZSNQVw3UMYXZKhCvdM1mg4L9Lu91dyt3p21O32MppTCaehRGp+CO/cQPn8PErUO/qqvnEseE8OGzGPj69xa+da6dCdTOpFLqgVdnt01Pz08aNZCsj0/Czg7f3jWz1R5QirB5svQEwXenH+vyt2TXZks9Hl0uNVWYhTbqX0u8IY9WgcDLiA+0x7VpvzUH6gtIaxAnBemO3kiOBYc6DrmLQT0y0Af0tgSeOOvxs7ksogemMdJkdvOPoJGwTdSBP/y7A+85CQAA\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var container = '_1jh8pss0';
export var containerWorkspaceInfo = '_1jh8pss8';
export var settingButton = '_1jh8pss9';
export var workspaceActiveStatus = '_1jh8pss6';
export var workspaceInfo = '_1jh8pss3';
export var workspaceInfoSlide = '_1jh8pss2';
export var workspaceInfoSlider = '_1jh8pss1';
export var workspaceInfoTooltip = '_1jh8pss7';
export var workspaceName = '_1jh8pss4';
export var workspaceStatus = '_1jh8pss5';