import {
  GlobalContextService,
  GlobalStateService,
  LiveData,
  useLiveData,
  useService,
  WorkspaceService,
} from '@toeverything/infra';
import { useCallback, useMemo } from 'react';

/* DNB_FIX добавлен useActiveSidebar*/

export const useActiveSidebar = () => {
  const workspace = useService(WorkspaceService).workspace;

  const isSharedMode = workspace.openOptions.isSharedMode;

  const pageId = useLiveData(
    useService(GlobalContextService).globalContext.docId.$
  );

  const currentWorkspace = useMemo(() => workspace.id, [workspace.id]);

  const RIGHT_SIDEBAR = useMemo(
    () =>
      `global-state:workspace-state:${currentWorkspace}:page:${pageId}:rightsidebar`,
    [currentWorkspace, pageId]
  );

  const RIGHT_SIDEBAR_TABS_ACTIVE_KEY = useMemo(
    () =>
      `global-state:workspace-state:${currentWorkspace}:page:${pageId}:rightsidebar:tabs:active`,
    [currentWorkspace, pageId]
  );

  const globalState = useService(GlobalStateService).globalState;

  const globalStateChange = useCallback(
    (key: string, value: string | boolean) => {
      globalState.set(key, value);
    },
    [globalState]
  );

  const activeRightSidebar = useLiveData(
    LiveData.from(globalState.watch<boolean>(RIGHT_SIDEBAR), false)
  );

  const activeTabName = useLiveData(
    LiveData.from(
      globalState.watch<string>(RIGHT_SIDEBAR_TABS_ACTIVE_KEY),
      'journal'
    )
  );
  return {
    activeRightSidebar,
    RIGHT_SIDEBAR,
    RIGHT_SIDEBAR_TABS_ACTIVE_KEY,
    activeTabName,
    globalStateChange,
    isSharedMode,
  };
};
