import { type Framework, WorkspaceScope } from '@toeverything/infra';

import { AuthService } from '../cloud';
import {
  FormbricksService,
  FormbricksWorkspaceContextService,
} from './services/formbricks';

export function configureFormbricksModule(framework: Framework) {
  framework.service(FormbricksService, [AuthService]);
  framework.scope(WorkspaceScope).service(FormbricksWorkspaceContextService);
}
