import clsx from 'clsx';
import { motion } from 'framer-motion';

import { Button, type ButtonType } from '../button';
import {
  cookieBannerStyle,
  cookieButtonsStyle,
  cookieButtonStyle,
  cookieSectionStyle,
} from './index.css';

export interface IBannerButtons {
  type: ButtonType;
  text: string;
  color: boolean;
  onClick: () => void;
}

export const Banner = ({
  renderContent,
  buttons,
  icon,
  position,
  show,
}: {
  renderContent: () => JSX.Element;
  buttons: IBannerButtons[];
  icon?: () => JSX.Element;
  position?: 'left' | 'right';
  show: boolean;
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: show ? 1 : 0, y: show ? 0 : '100%' }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={clsx(cookieBannerStyle, {
        left: position === 'left',
      })}
    >
      <div className={cookieSectionStyle}>
        {icon?.()}
        {renderContent()}
      </div>
      <div className={cookieButtonsStyle}>
        {buttons.map((item, index) => (
          <Button
            key={index}
            type={item.type as ButtonType}
            className={item.color ? cookieButtonStyle : undefined}
            size="large"
            onClick={item.onClick}
          >
            {item.text}
          </Button>
        ))}
      </div>
    </motion.div>
  );
};
