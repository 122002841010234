import { notify } from '@affine/component';
import { AuthInput, ModalHeader } from '@affine/component/auth-components';
import { Button } from '@affine/component/ui/button';
import { authAtom } from '@affine/core/atoms';
import { useAsyncCallback } from '@affine/core/hooks/affine-async-hooks';
import { track } from '@affine/core/mixpanel';
import { Trans, useI18n } from '@affine/i18n';
import { ArrowDownBigIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AuthService, ServerConfigService } from '../../../modules/cloud';
import { emailRegex } from '../../../utils/email-regex';
import type { AuthPanelProps } from './index';
import { OAuth } from './oauth';
import * as style from './style.css';
import { Captcha, useCaptcha } from './use-captcha';

function validateEmail(email: string) {
  return emailRegex.test(email);
}

//DNB_FIX добавить Legal
const Legal = () => {
  return (
    <div className={clsx(style.legalContainerStyle, style.authMessage)}>
      {'Я согласен с '}
      <a
        href="https://mkskom.ru/legal-documents#agree"
        target="_blank"
        rel="noreferrer"
      >
        Пользовательским соглашением
      </a>
      {' и '}
      <a
        href="https://mkskom.ru/legal-documents#policy"
        target="_blank"
        rel="noreferrer"
      >
        Политикой конфиденциальности
      </a>
    </div>
  );
};

export const SignIn: FC<AuthPanelProps> = ({
  setAuthState,
  setAuthEmail,
  email,
  onSignedIn,
}) => {
  const t = useI18n();
  const authService = useService(AuthService);
  const [searchParams] = useSearchParams();
  const [isMutating, setIsMutating] = useState(false);
  const [verifyToken, challenge] = useCaptcha();
  const isSelfHosted = runtimeConfig.isSelfHosted;

  const serverConfig = useService(ServerConfigService).serverConfig;
  const enabledAuthEmail = useLiveData(
    serverConfig.config$.map(r => r?.enabledAuthEmail)
  );

  const [isValidEmail, setIsValidEmail] = useState(true);
  const { openModal } = useAtomValue(authAtom);

  useEffect(() => {
    const timeout = setInterval(() => {
      // revalidate session to get the latest status
      authService.session.revalidate();
    }, 3000);
    return () => {
      clearInterval(timeout);
    };
  }, [authService]);
  const loginStatus = useLiveData(authService.session.status$);
  if (loginStatus === 'authenticated' && openModal) {
    onSignedIn?.();
  }

  const onContinue = useAsyncCallback(async () => {
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    setIsValidEmail(true);

    setIsMutating(true);

    setAuthEmail(email);
    try {
      const { hasPassword, isExist: isUserExist } =
        await authService.checkUserByEmail(email);

      if (verifyToken) {
        if (isUserExist) {
          // provider password sign-in if user has by default
          //  If with payment, onl support email sign in to avoid redirect to affine app
          if (hasPassword) {
            setAuthState('signInWithPassword');
          } else {
            track.$.$.auth.signIn();
            await authService.sendEmailMagicLink(
              email,
              verifyToken,
              challenge,
              searchParams.get('redirect_uri')
            );
            setAuthState('afterSignInSendEmail');
          }
        } else {
          await authService.sendEmailMagicLink(
            email,
            verifyToken,
            challenge,
            searchParams.get('redirect_uri')
          );
          track.$.$.auth.signUp();
          setAuthState('afterSignUpSendEmail');
        }
      }
    } catch (err) {
      console.error(err);

      // TODO(@eyhn): better error handling
      notify.error({
        title: 'Ошибка отправки email. Пожалуйста, попробуйте снова.',
      });
    }

    setIsMutating(false);
  }, [
    authService,
    challenge,
    email,
    searchParams,
    setAuthEmail,
    setAuthState,
    verifyToken,
  ]);

  const onChange = useCallback(
    (value: string) => {
      setAuthEmail(value);
    },
    [setAuthEmail]
  );

  return (
    <>
      <ModalHeader
        title={`${t['com.affine.auth.sign.in']()} ${t['com.affine.brand.affineCloud']()}`}
        subTitle={''}
      />
      {/* DNB_FIX */}
      <p className={style.authModalText}>
        Мы выполним вход или создадим учетную запись, если у Вас ее еще нет
      </p>

      <OAuth redirectUri={searchParams.get('redirect_uri')} />

      {enabledAuthEmail && (
        <div className={style.authModalContent}>
          <AuthInput
            label={t['com.affine.settings.email']()}
            placeholder={t['com.affine.auth.sign.email.placeholder']()}
            value={email}
            onChange={onChange}
            error={!isValidEmail}
            errorHint={
              isValidEmail ? '' : t['com.affine.auth.sign.email.error']()
            }
            onEnter={onContinue}
          />

          {verifyToken ? null : <Captcha />}

          {verifyToken ? (
            <Button
              size="extraLarge"
              data-testid="continue-login-button"
              block
              loading={isMutating}
              suffix={
                <ArrowDownBigIcon
                  width={20}
                  height={20}
                  style={{
                    transform: 'rotate(-90deg)',
                    //DNB_FIX цвет
                    color: 'var(--affine-white)',
                  }}
                />
              }
              onClick={onContinue}
              //DNB_FIX цвет и disabled
              disabled={!email}
              variant="primary"
              style={{
                backgroundColor: !email ? '#C9C9C9' : '',
                width: '100%',
              }}
            >
              {t['com.affine.auth.sign.email.continue']()}
            </Button>
          ) : null}
        </div>
      )}
      <>
        {/* DNB_FIX в selfhosted не показываем политику */}
        {!isSelfHosted ? (
          <div className={style.authMessage}>
            {/*prettier-ignore*/}
            <Trans i18nKey="com.affine.auth.sign.message">
              By clicking &quot;Continue with Google/Email&quot; above, you acknowledge that
              you agree to AFFiNE&apos;s <a href="https://affine.pro/terms" target="_blank" rel="noreferrer">Terms of Conditions</a> and <a href="https://affine.pro/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>.
          </Trans>
          </div>
        ) : (
          <Legal />
        )}
      </>
    </>
  );
};
