import { builtInTemplates as builtInEdgelessTemplates } from '@affine/templates/edgeless';
import { edgelessTemplate } from '@affine/templates/edgeless-template';
import { builtInTemplates as builtInStickersTemplates } from '@affine/templates/stickers';
import type { TemplateManager } from '@blocksuite/blocks';
import { EdgelessTemplatePanel } from '@blocksuite/blocks';

EdgelessTemplatePanel.templates = edgelessTemplate;

edgelessTemplate.extend(builtInStickersTemplates as TemplateManager);
edgelessTemplate.extend(builtInEdgelessTemplates as TemplateManager);
