import { cookieIconStyle } from './index.css';
export const CookieSvg = () => {
  return (
    <svg
      className={cookieIconStyle}
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.003 21a9.003 9.003 0 0 0 8.996-8.658c.006-.153-.16-.25-.298-.181-2.476 1.247-4.006-.077-3.757-1.854a.23.23 0 0 0-.252-.257c-2.171.303-3.086-1.014-2.744-2.804a.225.225 0 0 0-.201-.261c-2.043-.182-2.212-2.54-1.861-3.69.043-.142-.059-.3-.207-.295a9.003 9.003 0 0 0 .324 18M15 16.354l.354-.354M10 17.354l.354-.354M8 8.354 8.354 8M7 13.354 7.354 13M12 12.354l.354-.354"></path>
    </svg>
  );
};
