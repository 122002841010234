import { type SVGProps } from 'react';

export const DNBLogoIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1.8rem"
      height="1.8rem"
      viewBox="0 0 75 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ userSelect: 'none', flexShrink: 0 }}
      {...props}
    >
      <path
        d="M46.4636 0.22238L71.4636 7.87544C73.565 8.51873 75 10.4588 75 12.6564V63.5937C75 66.063 73.1974 68.1625 70.7565 68.5361L45.7565 72.3626C42.7289 72.826 40 70.4831 40 67.4202V5.00338C40 1.64236 43.2498 -0.76144 46.4636 0.22238Z"
        fill="#0168DE"
      />
      <path
        d="M36.4636 0.22238L61.4636 7.87544C63.565 8.51873 65 10.4588 65 12.6564V63.5937C65 66.063 63.1974 68.1625 60.7565 68.5361L35.7565 72.3626C32.7289 72.826 30 70.4831 30 67.4202V5.00338C30 1.64236 33.2498 -0.76144 36.4636 0.22238Z"
        fill="#0168DE"
        fillOpacity="0.7"
      />
      <path
        d="M26.4636 0.22238L51.4636 7.87544C53.565 8.51873 55 10.4588 55 12.6564V63.5937C55 66.063 53.1974 68.1625 50.7565 68.5361L25.7565 72.3626C22.7289 72.826 20 70.4831 20 67.4202V5.00338C20 1.64236 23.2498 -0.76144 26.4636 0.22238Z"
        fill="#0168DE"
        fillOpacity="0.5"
      />
      <path
        d="M16.4636 0.22238L41.4636 7.87544C43.565 8.51873 45 10.4588 45 12.6564V63.5937C45 66.063 43.1974 68.1625 40.7565 68.5361L15.7565 72.3626C12.7289 72.826 10 70.4831 10 67.4202V5.00338C10 1.64236 13.2498 -0.76144 16.4636 0.22238Z"
        fill="#0168DE"
        fillOpacity="0.2"
      />
      <path
        d="M6.46357 0.22238L31.4636 7.87544C33.565 8.51873 35 10.4588 35 12.6564V63.5937C35 66.063 33.1974 68.1625 30.7565 68.5361L5.75649 72.3626C2.72888 72.826 0 70.4831 0 67.4202V5.00338C0 1.64236 3.24976 -0.76144 6.46357 0.22238Z"
        fill="#0168DE"
        fillOpacity="0.1"
      />
    </svg>
  );
};

// DNB_FIX добавить Яндекс лого
export const YandexLogoIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_250_13941)">
        <path
          d="M12.8182 23.4546C14.3224 23.4546 15.8119 23.1583 17.2017 22.5827C18.5914 22.0071 19.8541 21.1633 20.9178 20.0997C21.9814 19.036 22.8252 17.7733 23.4008 16.3835C23.9765 14.9938 24.2727 13.5043 24.2727 12.0001C24.2727 10.4958 23.9765 9.00634 23.4008 7.61661C22.8252 6.22688 21.9814 4.96414 20.9178 3.90049C19.8541 2.83684 18.5914 1.9931 17.2017 1.41746C15.8119 0.841813 14.3224 0.545532 12.8182 0.545532C9.78026 0.545532 6.86675 1.75235 4.71861 3.90049C2.57046 6.04864 1.36365 8.96214 1.36365 12.0001C1.36365 15.038 2.57046 17.9515 4.71861 20.0997C6.86675 22.2478 9.78026 23.4546 12.8182 23.4546Z"
          fill="#F8604A"
        />
        <path
          d="M14.6187 19.1619H17.1114V4.83276H13.4787C9.82418 4.83276 7.90418 6.70913 7.90418 9.46913C7.90418 11.6619 8.9569 12.9764 10.8333 14.3128L7.56599 19.1564H10.2769L13.9096 13.7346L12.6442 12.8946C11.1169 11.8582 10.3696 11.0619 10.3696 9.32731C10.3696 7.80549 11.4442 6.77458 13.4896 6.77458H14.6078V19.1564H14.6133L14.6187 19.1619Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_250_13941">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.818504)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

// TODO DNB_FIX поправить fill у иконки
export const VKLogoIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_250_13946)">
        <path
          d="M0.818512 10.56C0.818512 5.58195 0.818512 3.09295 2.36499 1.54648C3.91146 0 6.40046 0 11.3785 0H12.2585C17.2366 0 19.7255 0 21.2721 1.54648C22.8185 3.09295 22.8185 5.58195 22.8185 10.56V11.44C22.8185 16.4181 22.8185 18.907 21.2721 20.4535C19.7255 22 17.2366 22 12.2585 22H11.3785C6.40046 22 3.91146 22 2.36499 20.4535C0.818512 18.907 0.818512 16.4181 0.818512 11.44V10.56Z"
          fill="white"
        />
        <path
          d="M12.5244 15.8493C7.51019 15.8493 4.65023 12.4118 4.53107 6.69177H7.04273C7.12523 10.8901 8.97685 12.6684 10.4435 13.0351V6.69177H12.8086V10.3126C14.2569 10.1568 15.7784 8.50677 16.2918 6.69177H18.6568C18.2626 8.92844 16.6126 10.5784 15.4393 11.2568C16.6126 11.8068 18.4919 13.2459 19.2069 15.8493H16.6035C16.0443 14.1076 14.6511 12.7601 12.8086 12.5768V15.8493H12.5244Z"
          fill="#1E96EB"
        />
      </g>
      <defs>
        <clipPath id="clip0_250_13946">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0.818512)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

// TODO DNB_FIX поправить fill у иконки
export const KeycloakLogoIcon = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_250_13924)">
        <path
          d="M3.46633 5.50986L6.57103 0.151044L19.074 0.149414L22.1619 5.56146L22.1657 16.3877L19.0746 21.7965L6.57538 21.8008L3.43483 16.3883L3.46633 5.50986Z"
          fill="#4D4D4D"
        />
        <path
          d="M3.43516 16.3867H9.0699L5.97498 10.9328L8.49198 5.51097L3.46667 5.50989L0.318512 10.9768"
          fill="#EDEDED"
        />
        <path
          d="M10.7781 16.387H14.8968L18.5387 11.0787L14.9604 5.56238H10.1154L7.19701 10.8929L10.7781 16.387Z"
          fill="#E0E0E0"
        />
        <path
          d="M0.318512 10.9764L3.43516 16.3885H9.07045L6.0092 10.9949L0.318512 10.9764Z"
          fill="#ACACAC"
        />
        <path
          d="M7.25687 10.9773L10.7793 16.3883H14.8975L18.4824 10.9795L7.25687 10.9773Z"
          fill="#9E9E9E"
        />
        <path
          d="M8.6524 10.9799L7.58944 11.2895L6.56938 10.9793L10.7338 3.76074L11.7756 5.56295"
          fill="#00B8E3"
        />
        <path
          d="M11.7718 16.3868L10.7355 18.1966L7.96915 15.2163L6.56779 10.9813V10.9791H8.65244"
          fill="#33C6E9"
        />
        <path
          d="M6.56815 10.9798H6.56598V10.9815L5.52474 12.7858L4.47916 10.9896L5.5394 9.15047L8.65062 3.76233H10.7331"
          fill="#008AAA"
        />
        <path
          d="M18.0296 16.3868H24.3368L24.333 5.56165H18.0296V16.3868Z"
          fill="#D4D4D4"
        />
        <path
          d="M18.0296 10.9944V16.388H24.3254V10.9944H18.0296Z"
          fill="#919191"
        />
        <path
          d="M10.7345 18.199H8.65044L5.5251 12.7853L6.56634 10.9821L10.7345 18.199Z"
          fill="#00B8E3"
        />
        <path
          d="M19.0673 10.9801L14.9018 18.1976C14.5189 17.6349 13.8628 16.3889 13.8628 16.3889L16.9876 10.979L19.0673 10.9801Z"
          fill="#008AAA"
        />
        <path
          d="M16.9827 18.1973L14.9024 18.1967L19.0684 10.9792L20.1091 9.17703L21.1531 10.9912M19.0679 10.9798H16.9887L13.8628 5.56285L14.8975 3.76282L17.4275 6.97941L19.0679 10.9798Z"
          fill="#00B8E3"
        />
        <path
          d="M20.1086 9.17614V9.17777L19.0673 10.98L14.8975 3.76355L16.9838 3.76464L20.1086 9.17614Z"
          fill="#33C6E9"
        />
      </g>
      <defs>
        <clipPath id="clip0_250_13924">
          <rect
            width="24"
            height="21.7006"
            fill="white"
            transform="translate(0.318512 0.149658)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
