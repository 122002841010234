import { IconButton } from '@affine/component';
import { RightSidebarIcon } from '@blocksuite/icons/rc';
import {
  GlobalContextService,
  useLiveData,
  useService,
} from '@toeverything/infra';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import { Suspense, useCallback, useEffect } from 'react';

import { AffineErrorBoundary } from '../../../components/affine/affine-error-boundary';
import { appSidebarOpenAtom } from '../../../components/app-sidebar/index.jotai';
import { SidebarSwitch } from '../../../components/app-sidebar/sidebar-header/sidebar-switch';
import { ViewService } from '../services/view';
import { WorkbenchService } from '../services/workbench';
import * as styles from './route-container.css';
import { useActiveSidebar } from './use-is-active-sidebar';
import { useViewPosition } from './use-view-position';
import { ViewBodyTarget, ViewHeaderTarget } from './view-islands';

export interface Props {
  route: {
    Component: React.ComponentType;
  };
}

const ToggleButton = ({
  onToggle,
  className,
  show,
}: {
  onToggle?: () => void;
  className: string;
  show: boolean;
}) => {
  return (
    <IconButton
      size="24"
      onClick={onToggle}
      className={className}
      data-show={show}
    >
      <RightSidebarIcon />
    </IconButton>
  );
};

export const RouteContainer = ({ route }: Props) => {
  const viewPosition = useViewPosition();
  const leftSidebarOpen = useAtomValue(appSidebarOpenAtom);
  const workbench = useService(WorkbenchService).workbench;
  const view = useService(ViewService).view;
  /*DNB_FIX activeRightSidebar вместо sidebarOpen*/
  /*const sidebarOpen = useLiveData(workbench.sidebarOpen$);*/

  /*  DNB_FIX */
  const { activeRightSidebar, RIGHT_SIDEBAR, globalStateChange, isSharedMode } =
    useActiveSidebar();

  // DNB_FIX
  useEffect(() => {
    if (!isSharedMode && activeRightSidebar) {
      workbench.toggleSidebar();
    }
  }, [activeRightSidebar, workbench, isSharedMode]);

  const handleToggleSidebar = useCallback(() => {
    /*  DNB_FIX */
    /*workbench.toggleSidebar();*/
    globalStateChange(RIGHT_SIDEBAR, true);
  }, [RIGHT_SIDEBAR, globalStateChange]);

  // DNB_FIX
  const pageId = useLiveData(
    useService(GlobalContextService).globalContext.docId.$
  );

  return (
    <div className={styles.root}>
      <div
        className={clsx(styles.header, {
          'full-screen': pageId,
        })}
      >
        {viewPosition.isFirst && (
          <SidebarSwitch
            show={!leftSidebarOpen}
            className={styles.leftSidebarButton}
          />
        )}
        <ViewHeaderTarget
          viewId={view.id}
          className={styles.viewHeaderContainer}
        />
        {/* DNB_FIX проверять на isSharedMode */}
        {viewPosition.isLast && !environment.isDesktop && !isSharedMode && (
          <ToggleButton
            show={/*  DNB_FIX */ /*!sidebarOpen*/ !activeRightSidebar}
            className={styles.rightSidebarButton}
            onToggle={handleToggleSidebar}
          />
        )}
      </div>

      <AffineErrorBoundary>
        <Suspense>
          <route.Component />
        </Suspense>
      </AffineErrorBoundary>
      <ViewBodyTarget viewId={view.id} className={styles.viewBodyContainer} />
    </div>
  );
};
