import { toast } from '@affine/component';
import { useDocCollectionHelper } from '@affine/core/hooks/use-block-suite-workspace-helper';
import { OrganizeService } from '@affine/core/modules/organize';
import { WorkbenchService } from '@affine/core/modules/workbench';
import { useI18n } from '@affine/i18n';
import { DocsService, initEmptyPage, useService } from '@toeverything/infra';
import { useCallback, useMemo } from 'react';

import type { DocCollection } from '../../../shared';

export const usePageHelper = (docCollection: DocCollection) => {
  const workbench = useService(WorkbenchService).workbench;
  const { createDoc } = useDocCollectionHelper(docCollection);
  const docRecordList = useService(DocsService).list;

  const organizeService = useService(OrganizeService);
  const folderTree = organizeService.folderTree;
  const rootFolder = folderTree.rootFolder;

  const t = useI18n();

  const isPreferredEdgeless = useCallback(
    (pageId: string) =>
      docRecordList.doc$(pageId).value?.mode$.value === 'edgeless',
    [docRecordList]
  );

  // DNB_FIX добавить параметр pageId и создать document
  const createPageAndOpen = useCallback(
    (mode?: 'page' | 'edgeless', open?: boolean | 'new-tab') => {
      const page = createDoc();
      initEmptyPage(page);
      docRecordList.doc$(page.id).value?.setMode(mode || 'page');
      if (open !== false)
        workbench.openDoc(page.id, {
          at: open === 'new-tab' ? 'new-tab' : 'active',
        });
      return page;
    },
    [createDoc, docRecordList, workbench]
  );

  const createEdgelessAndOpen = useCallback(
    (open?: boolean | 'new-tab') => {
      return createPageAndOpen('edgeless', open);
    },
    [createPageAndOpen]
  );

  const importFileAndOpen = useMemo(
    () => async () => {
      const { showImportModal } = await import('@blocksuite/blocks');
      const { promise, resolve, reject } =
        Promise.withResolvers<
          Parameters<
            NonNullable<Parameters<typeof showImportModal>[0]['onSuccess']>
          >[1]
        >();
      const onSuccess = (
        pageIds: string[],
        options: { isWorkspaceFile: boolean; importedCount: number }
      ) => {
        resolve(options);

        // DNB_FIX выводить сообщение на ru
        toast(`Успешно импортировался(-ись) документ(-ы)`);
        if (options.isWorkspaceFile) {
          workbench.openAll();
          return;
        }

        if (pageIds.length === 0) {
          return;
        }
        const pageId = pageIds[0];

        // DNB_FIX добавлять папку и документ в "Каталог"
        const title = docRecordList.doc$(pageId).value?.title$.value;
        const folderId = rootFolder.createFolder(
          title || t['com.affine.rootAppSidebar.organize.new-folders'](),
          rootFolder.indexAt('after')
        );

        const folerNode = folderTree.folderNode$(folderId).value;
        folerNode?.createLink('doc', pageId, folerNode.indexAt('after'));

        workbench.openDoc(pageId);
      };
      showImportModal({
        collection: docCollection,
        onSuccess,
        onFail: message => {
          reject(new Error(message));
        },
      });
      return await promise;
    },
    [docCollection, workbench, rootFolder, t, docRecordList, folderTree]
  );

  return useMemo(() => {
    return {
      isPreferredEdgeless,
      createPage: (open?: boolean | 'new-tab') =>
        createPageAndOpen('page', open),
      createEdgeless: createEdgelessAndOpen,
      importFile: importFileAndOpen,
    };
  }, [
    isPreferredEdgeless,
    createEdgelessAndOpen,
    createPageAndOpen,
    importFileAndOpen,
  ]);
};
