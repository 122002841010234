import "../core/src/components/affine/auth/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/auth/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VV246bMBB9z1dYG1VqHxxBAiTrqC+V9iuqqjIwEHeNjXzZZFvtv1cYSLhmSV4i2eOZM2fOHDa//eDEo5h76N8KoYKqnAlsZEnQzisvx9XHatOG+J0Qgqg18nh7EktjZEFQUD1CyMDFYMpZLghKQBhQvVTbmWoIJZJLRdAbVV8xplnGBGCXTEMiRUrVO3Yh36rgTAqDNfsLgwf1eeFieHVwApafDEH+JhzgoA5JU3XtvzxHLz8GIRvOxGsdZpWu4krJ6pam8VbxV5SdVDuXpJSaGSYrCmMtuTVQ5VE1QO844AVvo8EYgi53Le2jYYXdvubRIRTT5DVX0oqUIKOo0CVVIIy7kioFhZskg8u73F/0mPvttp6w1aCwBg6JIUhIAT3ckcO9ZLAPKeVz0lsmvR6cvYPTtrDf1y2kTJecvhOUcXAH1T9OmYKkLpJIbgtR3fyx2rCsgiIMCHPbBoTcemBmoNDd47s7eHB4RvXvVMlpSdChxj05j8+57tR/npWVo75UrJhY0XNTMvL67NbLd2apOREUhIusI+698ebfLFNRwzaHrLKHaN6EYm5h3FEw6Chx6KbhPDK1GW0sWboevtAbOUrU8Dw07qHU0mmpLYFwlZjXkdgA0kIvLa0CfD4xAwO3wpNDUrS9qdeepikTOUFe+2FqPE3RlFl9/Vw9OokuTT9TaihmGsfAGbyB+v5klIWnX46/MeD1bh/4od9LArOy6QRlN2vsye8hu1wf3G9+aZrNOoRfbt1r4NmERbXS8b2eopzK6rOP/0OgetxgCAAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accessMessage = '_14hl6blc';
export var authMessage = '_14hl6bl2';
export var authModalContent = '_14hl6bl0';
export var authModalText = '_14hl6blf';
export var captchaWrapper = '_14hl6bl1';
export var forgetPasswordButton = '_14hl6bl6';
export var forgetPasswordButtonRow = '_14hl6bl3';
export var legalContainerStyle = '_14hl6ble';
export var linkButton = '_14hl6bl5';
export var resendCountdown = '_14hl6bla';
export var resendCountdownInButton = '_14hl6blb';
export var resendWrapper = '_14hl6bl7';
export var sendMagicLinkButtonRow = '_14hl6bl4';
export var sentMessage = '_14hl6bl9';
export var sentRow = '_14hl6bl8';
export var userPlanButton = '_14hl6bld';