import { getBaseUrl } from '@affine/graphql';
import { MarkdownAdapter, ZipTransformer } from '@blocksuite/blocks';
import { assertExists } from '@blocksuite/global/utils';
import type { Doc } from '@blocksuite/store';
import { getAssetName, Job } from '@blocksuite/store';
import JSZip from 'jszip';

function createAssetsArchive(
  assetsMap: Map<string, Blob>,
  assetsIds: string[]
) {
  const zip = new JSZip();

  const assets = zip.folder('assets');
  assertExists(assetsMap);

  assetsMap.forEach((blob, id) => {
    if (!assetsIds.includes(id)) return;
    assets?.file(getAssetName(assetsMap, id), blob);
  });

  return zip;
}

function download(blob: Blob, name: string) {
  const element = document.createElement('a');
  element.setAttribute('download', name);
  const fileURL = URL.createObjectURL(blob);
  element.setAttribute('href', fileURL);
  element.style.display = 'none';
  document.body.append(element);
  element.click();
  element.remove();
  URL.revokeObjectURL(fileURL);
}

export class DocxTransformer {
  static async exportDoc(doc: Doc) {
    const job = new Job({ collection: doc.collection });
    const snapshot = await job.docToSnapshot(doc);

    const adapter = new MarkdownAdapter(job);

    const markdownResult = await adapter.fromDocSnapshot({
      snapshot,
      assets: job.assetsManager,
    });

    let downloadBlob: Blob;
    const pageTitle = doc.meta?.title || 'Untitled';
    let name: string;
    const contentBlob = new Blob([markdownResult.file], {
      type: 'plain/text',
    });
    if (markdownResult.assetsIds.length > 0) {
      const zip = createAssetsArchive(job.assets, markdownResult.assetsIds);

      zip.file('index.md', contentBlob);

      downloadBlob = await zip.generateAsync({ type: 'blob' });
      name = `${pageTitle}.zip`;
    } else {
      downloadBlob = contentBlob;
      name = `${pageTitle}.md`;
    }

    const isZip = name.includes('zip');

    const file = new File([downloadBlob], isZip ? 'index.zip' : 'index.md');
    const formData = new FormData();

    const options = {
      fileFrom: 'index.md',
      formatFrom: 'markdown',
      formatTo: 'docx',
    };

    formData.append('file', file);

    const isKeyOptions = (key: string): key is keyof typeof options => {
      return key in options;
    };

    Object.keys(options).forEach(key => {
      if (isKeyOptions(key)) {
        formData.append(key, options[key]);
      }
    });

    const blob = await fetch(getBaseUrl() + '/api/converter', {
      method: 'POST',
      body: formData,
    }).then(async res => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const buffer = await res.arrayBuffer();
      return new Blob([buffer]);
    });

    if (blob) {
      download(blob, `${pageTitle}.docx`);
    }
  }
}

export class SnapshotTransformer {
  static async exportDoc(doc: Doc) {
    const collection = doc.collection;
    const blob = await ZipTransformer.exportDocs(collection, [doc]);
    const pageTitle = doc.meta?.title || 'Untitled';

    if (blob) {
      download(blob, `${pageTitle}.zip`);
    }
  }
}
