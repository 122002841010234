import { Button } from '@affine/component';
import { WorkspacePermissionService } from '@affine/core/modules/permissions';
import { useI18n } from '@affine/i18n';
import { useLiveData, useService } from '@toeverything/infra';

import * as styles from './tag-list-header.css';

export const TagListHeader = ({ onOpen }: { onOpen: () => void }) => {
  const t = useI18n();
  // DNB_FIX получить isReadOnly
  const isReadOnly = useLiveData(
    useService(WorkspacePermissionService).permission.isReadOnly$
  );
  return (
    <div className={styles.tagListHeader}>
      <div className={styles.tagListHeaderTitle}>{t['Tags']()}</div>
      {/* DNB_FIX скрывать кнопку, если isReadOnly */}
      {!isReadOnly && (
        <Button
          className={styles.newTagButton}
          onClick={onOpen}
          data-testid="all-tags-new-button"
        >
          {t['com.affine.tags.empty.new-tag-button']()}
        </Button>
      )}
    </div>
  );
};
