// DNB_FIX добавить свою иконку
export const NotFoundPattern = () => {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M317.192 8.34494L487.858 60.5898C502.204 64.9814 512 78.2254 512 93.2282V440.96C512 457.817 499.694 472.149 483.031 474.7L312.365 500.822C291.696 503.986 273.067 487.991 273.067 467.082V40.9832C273.067 18.0386 295.252 1.62873 317.192 8.34494Z"
        fill="#0168DE"
      />
      <path
        d="M248.924 8.34511L419.591 60.59C433.937 64.9815 443.733 78.2255 443.733 93.2283V440.96C443.733 457.817 431.427 472.15 414.764 474.7L244.098 500.823C223.429 503.986 204.8 487.991 204.8 467.082V40.9834C204.8 18.0388 226.985 1.6289 248.924 8.34511Z"
        fill="#0168DE"
        fillOpacity="0.7"
      />
      <path
        d="M180.658 8.34511L351.325 60.59C365.671 64.9815 375.467 78.2255 375.467 93.2283V440.96C375.467 457.817 363.161 472.15 346.498 474.7L175.831 500.823C155.163 503.986 136.534 487.991 136.534 467.082V40.9834C136.534 18.0388 158.719 1.6289 180.658 8.34511Z"
        fill="#0168DE"
        fillOpacity="0.5"
      />
      <path
        d="M112.391 8.34511L283.058 60.59C297.404 64.9815 307.2 78.2255 307.2 93.2283V440.96C307.2 457.817 294.894 472.15 278.231 474.7L107.564 500.823C86.8958 503.986 68.2667 487.991 68.2667 467.082V40.9834C68.2667 18.0388 90.4517 1.6289 112.391 8.34511Z"
        fill="#0168DE"
        fillOpacity="0.2"
      />
      <path
        d="M44.1247 8.34511L214.791 60.59C229.137 64.9815 238.933 78.2255 238.933 93.2283V440.96C238.933 457.817 226.628 472.15 209.964 474.7L39.2977 500.823C18.6292 503.986 6.10352e-05 487.991 6.10352e-05 467.082V40.9834C6.10352e-05 18.0388 22.1851 1.6289 44.1247 8.34511Z"
        fill="#0168DE"
        fillOpacity="0.1"
      />
    </svg>
  );
};
